<template>
    <div class="gradeDivsingle" id="printableArea">
        
        <div class="topDiv">
            
            <div class="leftjustified rightborder">
                <div class="studentinfoandpic">
                    <img :src="imagePathPerson" class="img" v-if="ShowStudentImage"/>
                    <div class="">
                        <p class="studentinfolabel larger">{{ selectedInstance.StudentName }}</p>
                        <p class="studentinfolabel ">{{ selectedInstance.Name }}</p>
                        <p class="studentinfolabel ">{{ selectedInstance.LocationName }}</p>
                        
                        <p class="studentinfolabel " v-if="selectedInstance.truckNumber!=null">Truck: {{ selectedInstance.truckNumber }}</p>
                        <p class="studentinfolabel " v-if="selectedInstance.trailerNumber!=null">Trailer: {{ selectedInstance.trailerNumber }}</p>
                        <p class="studentinfolabel " v-if="selectedInstance.routeNumber!=null">Route: {{ selectedInstance.routeNumber }}</p>
                        
                        <p class="studentinfolabel " v-if="selectedInstance.miles!=null">Miles: {{ selectedInstance.miles }}</p>
                        <p class="studentinfolabel " v-if="selectedInstance.AccumulatedTime_Course!=''">Accum. Class Time: {{ selectedInstance.AccumulatedTime_Course }}</p>
                        <p class="studentinfolabel " v-if="selectedInstance.AccumulatedTime_BTW!=''">Accum. BTW Time: {{ selectedInstance.AccumulatedTime_BTW }}</p>
                        <p class="studentinfolabel ">Inst: {{ selectedInstance.InstructorName }}</p>
                        <p class=" redlabel" v-if="selectedInstance.criticalFailureCount>0">{{ CriticalMissText }}: {{ selectedInstance.criticalFailureCount }}</p>
                        <div class="horizFlex" v-if="selectedInstance.signatureFileName!=null && selectedInstance.signatureFileName!=''">
                            <label class="studentinfolabel ">Instr:</label>
                            <img :src="selectedInstance.signatureFileName" class="sig"/>
                        </div>
                        <div class="horizFlex" v-if="selectedInstance.signatureFileName_Student!=null && selectedInstance.signatureFileName_Student!=''">
                            <label class="studentinfolabel ">Student:</label>
                            <img :src="selectedInstance.signatureFileName_Student" class="sig"/>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="comments leftjustified">
                <div :class="commentlabelscrollclass">
                    <p class="">Previous comments: {{ selectedInstance.AccumulatedComments }}</p>
                </div>
                
                <div>
                    <label class="margin10" v-if="!selectedInstance.IsLocked">Add Comment</label>
                    <div class="margin10" v-if="!selectedInstance.IsLocked">

                        <select name="" id="" class='entrySelect' required v-if="useCommentList" v-model="commentId" @change="selectComment()">
                            <option v-for="c in commentList" :key="c.id" :value="c.id" >
                                {{c.name}}
                            </option>
                        </select>
                        <textarea class="commentinput2" v-model="selectedTimeData.enteredComment" v-if="showCommentText" ></textarea>
                    </div>
                </div>
                
                <!-- <smallbutton toplabel="Add Comment" @click="addComment()" buttonclass="buttonprimary innerbuttonwide" v-if="!selectedInstance.IsLocked"/> -->
                
            </div>
            <div class="leftborder">
                <div class="vertFlex">
                    
                    <smallbutton toplabel="Submit Grades" @click="savegrades(true)" buttonclass="buttonprimary finishbutton" v-if="!selectedInstance.IsLocked"/>
                    <confirm v-if="!selectedInstance.IsLocked" buttonclass="buttonprimary finishbutton" :showButton="paramTrue" body="Do you just want to save your progress? If you are finished grading, please click on the Submit Grades button instead." title="Save Progress"
                            @yes="savegrades(false)" noLabel="Cancel"/>
                    <!-- <smallbutton toplabel="Save Progress" @click="savegrades(false)" buttonclass="buttonprimary finishbutton" v-if="!selectedInstance.IsLocked"/> -->
                    <smallbutton toplabel="Close" @click="home" buttonclass="buttonprimary finishbutton" v-if="selectedInstance.IsLocked"/>
                    <linkbutton toplabel="Cancel" @click="cancelGrading" buttonclass="buttonprimary actionbutton" v-if="!selectedInstance.IsLocked"/>
                    
                </div>

            </div>
            
        </div>
        <label class="responsemessage" v-if="responseMessage!=null">{{ responseMessage }}</label>
       
        <label class="pleasewait margin10" v-if="pleaseWait">Please Wait...</label>
            
        <div class="gradesteps">
            <div class="horizFlexStretch">
                <div>
                    <smallbutton :toplabel="ColumnsLabel" @click="columns()" buttonclass="buttonprimary innerbuttonwide" v-if="!selectedInstance.IsLocked"/>
                    <smallbutton toplabel="Instructor Sign"  @click="openSignModal(selectedGradeSheet.id, selectedGradeSheet.id,selectedGradeSheet.id)" 
                        buttonclass="buttonprimary innerbuttonwide" v-if="ShowSignature"/>
                    <smallbutton toplabel="Student Sign"  @click="openSignModal_Student(selectedGradeSheet.id, selectedGradeSheet.id,selectedGradeSheet.id)" 
                        buttonclass="buttonprimary innerbuttonwide" v-if="selectedGradeSheet.showStudentSignature"/>
                    <smallbutton toplabel="Print" @click="print('printableArea')" buttonclass="buttonprimary innerbutton" v-if="selectedInstance.IsLocked"/>
                    <smallbutton toplabel="Add File"  @click="addfile" buttonclass="buttonprimary innerbutton"/>
                    <imagealldoc ref="imgdoc" fileAccept="*.*"  :allowFileSelect="paramTrue" :allowCapture="paramFalse" @saveimg="saveimg_document"  :showImage='paramFalse' />
                    <div>                    
                        <linkbutton toplabel="view preferences" @click="editPreferences"/>
                    </div>
                </div>
                <div v-if="selectedInstance.isFailure">
                    <p class="responsemessage">{{FailedText}}</p>
                </div>

                <!-- REGULAR -->
                <div class="horizFlex wrap" v-if="isManualTime && !selectedGradeSheet.isComboSequence"> 
                    
                    <p class="manualTimeLabel">Course Date/Time</p>
                    <input class="filterfield" type="date" v-model="selectedTimeData.enteredCourseDate" :disabled="disableTime">
                    <input class="filterfield" type="time" v-model="selectedTimeData.enteredCourseStartTime" step="900" 
                        :disabled="disableTime" v-if="showManualTime && !showTimeAs24">

                    <div class="time24" v-if="showManualTime && showTimeAs24">
                        <vue-timepicker input-width="80px" v-model="starttime24"  ></vue-timepicker>

                    </div>
                    
                    <p class="manualTimeLabel2" v-if="showManualTime">to</p>
                    <input class="filterfield" type="time" v-model="selectedTimeData.enteredCourseEndTime" step="900" 
                        :disabled="disableTime" v-if="showManualTime && !showTimeAs24">

                    <div class="time24" v-if="showManualTime && showTimeAs24">
                     <vue-timepicker  input-width="80px"  v-model="endtime24" ></vue-timepicker>
                    </div>

                    <p class="manualTimeLabel" v-if="showManualTime && showBTW">BTW Minutes</p>
                    <input class="filterfieldshort" v-model="selectedTimeData.enteredBTWMinutes" :disabled="disableTime" v-if="showManualTime && !showBTWDropDown && showBTW">

                    <select name="" id="" class='btwdropdown'  v-if="showManualTime && showBTWDropDown && showBTW" v-model="selectedBTWMinutes" :disabled="disableTime" >
                        <option v-for="c in btwMinutesList" :key="c.id" :value="c.id" >
                            {{c.name}}
                        </option>
                    </select>

                
                    <p class="manualTimeLabel" v-if="selectedGradeSheet.allowEnterMiles">Miles Driven</p>
                    <input class="filterfieldshort" v-model="selectedInstance.miles"  v-if="selectedGradeSheet.allowEnterMiles" :disabled="disableTime">
                  
                    <smallbutton toplabel="Update" @click="updateTime" buttonclass="buttonprimary innerbutton" v-if="disableTime"/>
                    <smallbutton toplabel="Save" @click="saveupdateTime" buttonclass="buttonprimary innerbutton" v-if="inTimeUpdate"/>
                    <smallbutton toplabel="Cancel" @click="cancelupdateTime" buttonclass="buttonprimary innerbutton" v-if="inTimeUpdate"/>
                </div>

                <!-- COMBO -->
                <div class="" v-if="isManualTime && selectedGradeSheet.isComboSequence"> 
                    <p class="manualTimeLabel">Course Date</p>
                    <input class="filterfield" type="date" v-model="selectedTimeData.enteredCourseDate" :disabled="disableTime">
                    <div class="horizFlex">
                        <p class="manualTimeLabel">Range Time</p>
                        <input class="filterfield" type="time" v-model="selectedTimeData.enteredCourseStartTime" step="900" 
                            :disabled="disableTime" v-if="showManualTime && !showTimeAs24">

                        <div class="time24" v-if="showManualTime && showTimeAs24">
                            <vue-timepicker input-width="80px" v-model="starttime24"  ></vue-timepicker>

                        </div>
                        
                        <p class="manualTimeLabel2" v-if="showManualTime">to</p>
                        <input class="filterfield" type="time" v-model="selectedTimeData.enteredCourseEndTime" step="900" 
                            :disabled="disableTime" v-if="showManualTime && !showTimeAs24">

                        <div class="time24" v-if="showManualTime && showTimeAs24">
                        <vue-timepicker  input-width="80px"  v-model="endtime24" ></vue-timepicker>
                        </div>

                        <p class="manualTimeLabel" v-if="showManualTime && showBTW">BTW Minutes</p>
                        <input class="filterfieldshort" v-model="selectedTimeData.enteredBTWMinutes" :disabled="disableTime" v-if="showManualTime && !showBTWDropDown && showBTW">

                        <select name="" id="" class='btwdropdown'  v-if="showManualTime && showBTWDropDown && showBTW" v-model="selectedBTWMinutes" :disabled="disableTime">
                            <option v-for="c in btwMinutesList" :key="c.id" :value="c.id" >
                                {{c.name}}
                            </option>
                        </select>
                    </div>
                    <div class="horizFlex">
                        <p class="manualTimeLabel">Road Time</p>
                        <input class="filterfield" type="time" v-model="selectedTimeData.enteredCourseStartTime2" step="900" 
                            :disabled="disableTime" v-if="showManualTime && !showTimeAs24">

                        <div class="time24" v-if="showManualTime && showTimeAs24">
                            <vue-timepicker input-width="80px" v-model="starttime242"  ></vue-timepicker>

                        </div>
                        
                        <p class="manualTimeLabel2" v-if="showManualTime">to</p>
                        <input class="filterfield" type="time" v-model="selectedTimeData.enteredCourseEndTime2" step="900" 
                            :disabled="disableTime" v-if="showManualTime && !showTimeAs24">

                        <div class="time24" v-if="showManualTime && showTimeAs24">
                        <vue-timepicker  input-width="80px"  v-model="endtime242" ></vue-timepicker>
                        </div>

                        <p class="manualTimeLabel" v-if="showManualTime && showBTW">BTW Minutes</p>
                        <input class="filterfieldshort" v-model="selectedTimeData.enteredBTWMinutes2" :disabled="disableTime" v-if="showManualTime && !showBTWDropDown && showBTW">

                        <select name="" id="" class='btwdropdown'  v-if="showManualTime && showBTWDropDown && showBTW" v-model="selectedBTWMinutes2" :disabled="disableTime" >
                            <option v-for="c in btwMinutesList" :key="c.id" :value="c.id" >
                                {{c.name}}
                            </option>
                        </select>
                        <p class="manualTimeLabel" v-if="selectedGradeSheet.allowEnterMiles">Miles Driven</p>
                        <input class="filterfieldshort" v-model="selectedInstance.miles"  v-if="selectedGradeSheet.allowEnterMiles" :disabled="disableTime">
                    </div>
                    
                    

                
                    
                  
                    <smallbutton toplabel="Update" @click="updateTime" buttonclass="buttonprimary innerbutton" v-if="disableTime"/>
                    <smallbutton toplabel="Save" @click="saveupdateTime" buttonclass="buttonprimary innerbutton" v-if="inTimeUpdate"/>
                    <smallbutton toplabel="Cancel" @click="cancelupdateTime" buttonclass="buttonprimary innerbutton" v-if="inTimeUpdate"/>
                </div>

                <!-- <smallbutton :toplabel="proficiencyLabel" @click="toggleProficient" :buttonclass="proficiencyClass" v-if="ShowProficiency"/> -->
                <div class="vertFlex" v-if="showProficiencyForThisEval && ShowProficiency">
                    <confirm :buttonclass="proficiencyClass" :showButton="paramTrue" body="Do you want to update the proficiency?" :title="proficiencyLabel"
                            @yes="toggleProficient" />
                    <p>{{ proficiencyInstructor }}</p>
                </div>
                
            </div>
            
           
           
            <!-- headers -->
            <div class="headerrow" v-if="zoom==1">
                <p class="headerlabel"></p>
                <div v-for="h in selectedInstanceList" :key="h.ID" :value="h.ID" >
                    <p :class="historyheaderlabelclass(h)">{{ formatDateTime(h.StartDateTime )}}</p>
                </div>
            </div>
           
            <!-- steps and components -->
            <div v-for="s in selectedGradeSheet.ProcedureStepList" :key="s.ID" :value="s.ID" class="step" >
                 <div > 
                    <!--class="graderow" -->
                    <p class=" steplabel">{{s.Name}}</p>
                </div>
                
                <div :class="componentclass">
                        <div v-for="c in s.StepComponentList" :key="c.ID" :value="c.ID" class="component">
                            <div v-if="c.IsActive">
                                <div class="graderow" v-if="zoom==1">
                                    <p class="componentlabel" v-if="!componentHasCheckOff(c)">{{getComponentName(c)}}</p>
                                    <div v-if="componentHasCheckOff(c)" class="horizFlex">
                                        <p class="componentlabel" >{{getComponentName(c)}}</p>
                                        <wheels :checkOffData="getCheckOffData(c.ID,selectedInstance)" v-if="c.checkOffStyle=='wheels'" @checkoff="checkoff" :componentId="c.ID" :instance="selectedInstance"/>
                                        <pins :checkOffData="getCheckOffData(c.ID,selectedInstance)" v-if="c.checkOffStyle=='pins'" @checkoff="checkoff" :componentId="c.ID" :instance="selectedInstance"/>
                                        <pins5 :checkOffData="getCheckOffData(c.ID,selectedInstance)" v-if="c.checkOffStyle=='pins5'" @checkoff="checkoff" :componentId="c.ID" :instance="selectedInstance"/>
                                    </div>
                                    <div v-for="h in selectedInstanceList" :key="h.ID" :value="h.ID" >
                                        <button v-if="!componentHasCheckOff(c)"  @click="grademe(c.ID,selectedInstance)" :class="gradebuttonclass(h)" :disabled="!isGradable(h)">
                                            <!-- <div class="gradeinner"> -->
                                                <p class="gradeboxlabel" v-if="!showCheck(c.ID,h)">{{ getGrade(c.ID,h) }}</p>
                                                <!-- v-if="!usingChecks" -->
                                                <img :src="checkImage"  class="gradecheckimage" v-if="showCheck(c.ID,h)">
                                            <!-- </div> -->
                                        
                                        </button>
                                        <button v-if="componentHasCheckOff(c)"   :class="gradebuttonclass(h)" :disabled="!isGradable(h)">
                                            <!-- <div class="gradeinner"> -->
                                                <p class="gradeboxlabel" v-if="!showCheck(c.ID,h)">{{ getGrade(c.ID,h) }}</p>
                                                <!-- v-if="!usingChecks" -->
                                                <img :src="checkImage"  class="gradecheckimage" v-if="showCheck(c.ID,h)">
                                            <!-- </div> -->
                                        
                                        </button>
                                    </div>
                                </div>
                                <div class="graderowmulti" v-if="zoom>1">
                                    <p class="componentlabel" v-if="!componentHasCheckOff(c)">{{getComponentName(c)}}</p>
                                    <div v-if="componentHasCheckOff(c)" class="horizFlex">
                                        <p class="componentlabel" >{{getComponentName(c)}}</p>
                                        <wheels :checkOffData="getCheckOffData(c.ID,selectedInstance)" v-if="c.checkOffStyle=='wheels'" @checkoff="checkoff" :componentId="c.ID" :instance="selectedInstance"/>
                                        <pins :checkOffData="getCheckOffData(c.ID,selectedInstance)" v-if="c.checkOffStyle=='pins'" @checkoff="checkoff" :componentId="c.ID" :instance="selectedInstance"/>
                                        <pins5 :checkOffData="getCheckOffData(c.ID,selectedInstance)" v-if="c.checkOffStyle=='pins5'" 
                                            @checkoff="checkoff" :componentId="c.ID" :instance="selectedInstance"/>
                                        <flashers :checkOffData="getCheckOffData(c.ID,selectedInstance)" v-if="c.checkOffStyle=='flashers8'" 
                                            @checkoff="checkoff" :componentId="c.ID" :instance="selectedInstance"/>
                                        <reflectives :checkOffData="getCheckOffData(c.ID,selectedInstance)" v-if="c.checkOffStyle=='diamond4'" 
                                            @checkoff="checkoff" :componentId="c.ID" :instance="selectedInstance"/>
                                    </div>
                                    
                                    <button v-if="!componentHasCheckOff(c)" @click="grademe(c.ID,selectedInstance)" :class="gradebuttonclass(selectedInstance)" :disabled="!isGradable(selectedInstance)">
                                        <!-- <div class="gradeinner"> -->
                                            <p class="gradeboxlabel" v-if="!showCheck(c.ID,selectedInstance)">{{ getGrade(c.ID,selectedInstance) }}</p>
                                            <!-- v-if="!usingChecks" -->
                                            <img :src="checkImage"  class="gradecheckimage" v-if="showCheck(c.ID,selectedInstance)">
                                        <!-- </div> -->
                                    </button>
                                    <button v-if="componentHasCheckOff(c)"  :class="gradebuttonclass(selectedInstance)" :disabled="!isGradable(selectedInstance)">
                                        <!-- <div class="gradeinner"> -->
                                            <p class="gradeboxlabel" v-if="!showCheck(c.ID,selectedInstance)">{{ getGrade(c.ID,selectedInstance) }}</p> 
                                            <!-- v-if="!usingChecks" -->
                                            <img :src="checkImage"  class="gradecheckimage" v-if="showCheck(c.ID,selectedInstance)">
                                        <!-- </div> -->
                                    </button>
                                </div>
                            </div>
                            
                        
                        </div>              
                </div>
                
                
            </div>
            <div class="total" v-if="selectedGradeSheet.showTotal">
                <p>{{ total }}</p>
            </div>
            
        </div>
        <grade15 ref="grade15" @select="setGrade" :gradeType="selectedGradeType"/>
        <addcomment ref="addcomment" @save="saveComment" :existingComment="selectedInstanceComment" />
        <preferences ref="preferences" @save="savePreferences" :cols="multColumnOverride"/>
        <signaturemodal ref="signdoc" @savesign="savesign_document" />
        <signaturemodal ref="signdoc_student" @savesign="savesign_document_student" />
        <modalmessage ref="modalmessage" title="OFFLINE" body="This action is unavailable Offline."/>
        <modalmessage ref="criticalmessage" :title="FixSubmitText('CRITICAL MISS')" :body="responseMessage" @close="closecriticalfailure"/>
        <confirm ref="confirmsubmit" :showButton="paramFalse" :body="confirmSubmitText" :title="confirmSubmitTitle"
                            @yes="savegrades(true,true)" :redText="paramTrue" />

         <confirmtimeconflict ref="confirmsubmittime"  :timeConflictList="timeConflictList"
                            @yes="finishsubmitinstortime" :redText="paramTrue" />
    </div>
</template>
  
  <script>
  import {mapFields} from 'vuex-map-fields'
  import datamethods from '../../services/datamethods.js'
  import format from '../../format.js'
  import smallbutton from '../controls/button.vue'
  import linkbutton from '../controls/linkbutton.vue'
  import grade15 from './grade15.vue'
  import signaturemodal from '../controls/signaturemodal.vue'
  import addcomment from './addcomment.vue'
  import modalmessage from '../controls/modalmessage.vue'
  import preferences from './preferences.vue'
  import confirm from '../actions/confirm.vue'
  import imagealldoc from '../controls/ImageAll.vue'
  import wheels from './wheels.vue'
  import pins from './pins.vue'
  import pins5 from './pins5.vue'
  import flashers from './flashers.vue'
  import reflectives from './reflectives.vue'
import VueTimepicker from 'vue3-timepicker/src/VueTimepicker.vue'
import confirmtimeconflict from '../actions/confirmtimeconflict.vue'
  export default {
      name:'gradesheet',
      mixins:[datamethods, format],
      components:{
          smallbutton,
          linkbutton,
          grade15,
       signaturemodal,
       addcomment,
       modalmessage,
       preferences,
       confirm,
       imagealldoc,
       wheels,
       pins,
       pins5,
       flashers,
        reflectives,
     VueTimepicker,
     confirmtimeconflict
     
   
      },
      data(){
          return{
            lastWasPass:false,

           starttime24:'',
           endtime24:'',
           starttime242:'',
           endtime242:'',
            zoom:1,

            selectedComponent:0,
            
            

            paramTrue:true,
            paramFalse:false,   
            listenersConnected:true,
            
           
            proficient:false,
            calcTotal:0,
            multColumnOverride:-1,

            inTimeUpdate:false,
            confirmSubmitText:'This evaluation fails the requirements.  Do you want to submit as failure?',
            confirmSubmitTitle:'Submit Failure',
            timeConflictList:[],
            
            selectedBTWMinutes:0,
            selectedBTWMinutes2:0,

            isCustom:false
            
          }
      },
      computed:{
        commentlabelscrollclass(){
            if(this.selectedInstance.IsLocked){
                return "commentlabelscroll_tall"

            }else{return "commentlabelscroll"}
        },
        useCommentList(){
                if(window.USE_COMMENT_LIST==undefined){return false}
                else{return window.USE_COMMENT_LIST}
            },
            showCommentText(){
                return !this.useCommentList || this.isCustom
            },
        warnAboutTimeConflicts(){
            if(window.WARN_ABOUT_TIME_CONFLICTS==undefined){return false}
            else{return window.WARN_ABOUT_TIME_CONFLICTS}
        },
        CriticalMissText(){
            var txt = 'Critical Miss'
            if(window.CRITICAL_TEXT_OVERRIDE!=undefined){
                txt = window.CRITICAL_TEXT_OVERRIDE
            }
            return txt
        },
        showTimeAs24(){
            if(window.SHOW_TIME_AS_24==undefined){
                return false
            }else{
               return window.SHOW_TIME_AS_24
            }
        },

        FailedText(){
            var failed = 'Failed'
            if(window.FAILED_TEXT_OVERRIDE!=undefined){
                failed=window.FAILED_TEXT_OVERRIDE
            }
            return failed
        },
        PassedText(){
            var failed = 'Passed'
            if(window.PASSED_TEXT_OVERRIDE!=undefined){
                failed=window.PASSED_TEXT_OVERRIDE
            }
            return failed
        },
        showBTWDropDown(){
            
            if(window.SHOW_BTW_DROP_DOWN==undefined){
                return false
            }else{
               return window.SHOW_BTW_DROP_DOWN
            }
        },
        showManualTime(){
            return this.selectedGradeSheet.showTimeOnManualEntry
        },
        showBTW(){
            return this.selectedGradeSheet.showBTW
        },
        disableTime(){
            return this.selectedInstance.IsLocked && !this.inTimeUpdate
        },
        ColumnsLabel(){
            if(this.zoom==1){return "View Today"}
            else{return "View History"}
        },
        selectedInstanceComment(){
            if(this.selectedInstance.OverallGradeList_Instructor.length>0){
                return this.selectedInstance.OverallGradeList_Instructor[0].Comment
            }
            
        },
        checkImage(){
            return require("@/assets/check.png")
        },
        total(){
            var total=0;
            var count=0;
            var avg;
            var outof100;
            var passcount =0;
            var pfcount = -1;
            this.selectedInstance.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.Grade!=null){
                    if(comp.Grade != 'NA' && comp.Grade !='IA'){
                        total = total + Number(comp.Grade)
                        count = count + 1
                    }
                    
                }
                if(comp.Grade=='P' || comp.Grade=='1'){
                    passcount = passcount + 1
                    
                }
                pfcount=pfcount + 1
            }
            avg = total/count;
            outof100=100-total;

         
            if(isNaN(avg)){avg=0}
            if(isNaN(outof100)){outof100=0}
            if(isNaN(total)){total=0}

            if(this.selectedGradeType=='pf' || this.selectedGradeType=='check'){
                return 'Passed ' + passcount + ' out of ' + count;
            }else if(this.selectedGradeSheet.showTotalAsSum){
                this.calcTotal = total
                return 'Total: ' + total;
            }else if(this.selectedGradeSheet.showTotalAsAvg){
                this.calcTotal = avg.toFixed(2);
                return 'Avg: ' + avg.toFixed(2);
            }else if(this.selectedGradeSheet.showTotalOutOf100){
                this.calcTotal=outof100multiColumnCount
                return 'Out of 100: ' + outof100;
            }else{
                this.calcTotal=total
                return total
            }

        },
        selectedGradeType(){
            if (this.selectedGradeSheet.gradeType==null){
                return '1to5'
            }else{return this.selectedGradeSheet.gradeType}
                
        },
        multiColumnCount(){
            var cols=4
            if(window.DEFAULT_COLUMN_COUNT!=undefined){cols=window.DEFAULT_COLUMN_COUNT}

            if(this.multColumnOverride>0){cols = this.multColumnOverride}
            return cols
        },
        ShowSignature(){
            if(window.SHOW_SIGNATURE==undefined){return false}
            else{return window.SHOW_SIGNATURE}
        },
        RequireSignature(){
            if(window.REQUIRE_SIGNATURE==undefined){return false}
            else{return window.REQUIRE_SIGNATURE}
        },
        RequireSignatureStudent(){
            if(window.REQUIRE_SIGNATURE_STUDENT==undefined){return false}
            else{return window.REQUIRE_SIGNATURE_STUDENT}
        },
        
        RequireManualEntry(){
            if(window.REQUIRE_MANUAL_ENTRY==undefined){return false}
            else{return window.REQUIRE_MANUAL_ENTRY}
        },
        ShowStudentImage(){
            if(window.SHOW_STUDENT_IMAGE==undefined){return false}
            else{return window.SHOW_STUDENT_IMAGE}
        },
        
        ShowProficiency(){
            if(window.SHOW_PROFICIENCY==undefined){return false}
            else{return window.SHOW_PROFICIENCY}
        },
        
        proficiencyClass(){
            if(this.selectedInstance.studentProficiency.StudentIsProficient_ThisItem==true){
                return "buttongreen innerbuttonprof3"
            }else{return "buttonred innerbuttonprof3"}
        },
        proficiencyLabel(){
            var lbl
            if(this.selectedInstance.studentProficiency.StudentIsProficient_ThisItem==true){
                lbl= "Is Proficient"
            }else{lbl= "NOT Proficient"}
            console.log('prpf ' + this.selectedInstance.Name.toLowerCase())
            if (this.selectedInstance.Name.toLowerCase().includes('range')){
                lbl=lbl + ' on Range'
            }else if (this.selectedInstance.Name.toLowerCase().includes('road')){
                lbl=lbl + ' on Road'
            }else if (this.selectedInstance.Name.toLowerCase().includes('hazmat')){
                lbl = lbl + ' on Hazmat'
            }
            console.log(lbl)
            return lbl
        },
        showProficiencyForThisEval(){
            //return !this.selectedInstance.Name.toLowerCase().includes('theory') 
            return this.selectedGradeSheet.showProficiency
        },
        proficiencyInstructor(){
            var lbl
            if(this.selectedInstance.studentProficiency.StudentIsProficient_ThisItem==false){
                lbl= ""
            }else if (this.selectedInstance.Name.toLowerCase().includes('range')){
                let id=this.selectedInstance.studentProficiency.Student_Range_Instructor
                
                lbl=this.getInstructorName(id)
            }else if (this.selectedInstance.Name.toLowerCase().includes('road')){
                let id=this.selectedInstance.studentProficiency.Student_Road_Instructor
                lbl=this.getInstructorName(id)
            }else if (this.selectedInstance.Name.toLowerCase().includes('hazmat')){
                let id=this.selectedInstance.studentProficiency.Student_Hazmat_Instructor
               
                if(id==null){return ''}
                lbl = this.getInstructorName(id)
            }
            return lbl
        },
        setproficiencyInstructor(){
            var lbl
            if (this.selectedInstance.Name.toLowerCase().includes('range')){
                this.selectedInstance.studentProficiency.Student_Range_Instructor= this.loggedInUser.user_personId

            }else if (this.selectedInstance.Name.toLowerCase().includes('road')){
                this.selectedInstance.studentProficiency.Student_Road_Instructor= this.loggedInUser.user_personId
            }else if (this.selectedInstance.Name.toLowerCase().includes('hazmat')){
                this.selectedInstance.studentProficiency.Student_Hazmat_Instructor= this.loggedInUser.user_personId
            }
            return lbl
        },
        componentclass(){
            return "componentdiv zoom" + this.zoom
        },
       
          //image
          imagePathPerson(){
            return this.selectedInstance.studentImageFileName
           
          },
          usingChecks(){
            return this.selectedGradeType=='check'
        },
        usingPassFail(){
            return this.selectedGradeType=='pf'
        },
          ...mapFields([
              'loggedInUser',
              'loggedInUser.isAdmin',
              'pleaseWait',
              'pleaseWaitText',
             'imageBasePath',
             'selectedGradeSheet',
             'selectedInstance',
             'selectedInstanceList',
             'isManualTime',
             'unchangedInstance',
             'online',
             'responseMessage',
             'selectedTimeData',
             'timeData',
             'instructorList',
             'btwMinutesList',
             'commentList'
          ]),
          AllowNAAsGrade(){
                if(window.ALLOW_NA_AS_GRADE==undefined){return false}
                else{return window.ALLOW_NA_AS_GRADE}
            },
            FailedText(){
            var failed = 'Failed'
            if(window.FAILED_TEXT_OVERRIDE!=undefined){
                failed=window.FAILED_TEXT_OVERRIDE
            }
            return failed
        },
        PassedText(){
            var failed = 'Passed'
            if(window.PASSED_TEXT_OVERRIDE!=undefined){
                failed=window.PASSED_TEXT_OVERRIDE
            }
            return failed
        },
    
      },
      props:{
          
          
          
      },
      methods:{
        selectComment(){
            var comment=null
            var id = this.commentId
            this.commentList.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    if(inst.id==id){
                        comment=inst.name
                    }
                } 
            console.log('selcomment')
            if(comment=='Custom'){
                this.isCustom=true
            }else{
                this.isCustom=false
                this.selectedTimeData.enteredComment=comment
          
            }
            
           },
           showComment(){
            var comment=this.selectedTimeData.enteredComment
            var id =-1
            var customid
            this.commentList.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    if(inst.name==comment){
                        id=inst.id
                    }else if(inst.name=='Custom'){
                        customid=inst.id
                    }
                }
            this.commentId=id
            if(id==-1){this.commentId=customid;this.isCustom=true}
           },
        changetimestart(eventData){
            this.selectedTimeData.enteredCourseStartTime=eventData.data.HH + ":" + eventData.data.mm
           
        },
        changetimeend(eventData){
            this.selectedTimeData.enteredCourseEndTime=eventData.data.HH + ":" + eventData.data.mm
           
        },
        checkoff(allchecked, checkoffdata, componentId, instance){
      
            //find component and set the checkoffdata
            this.selectedComponent=componentId
                this.selectedInstance=instance
            if(allchecked==true){
           
                if(this.selectedGradeType=='check'){
                    this.setGrade('P',checkoffdata)
                }
                else if(this.selectedGradeType=='pf'){
                    this.setGrade('P',checkoffdata)
                }
            }else{
                
                if(this.selectedGradeType=='check'){
                    this.setGrade(null,checkoffdata)
                }
                else if(this.selectedGradeType=='pf'){
                    this.setGrade(null,checkoffdata)
                }
            }
       
        },
        addfile(){
               // this.$refs.imgdoc.allowFileSelect=true
                //this.$refs.imgdoc.allowCapture=true
                this.$refs.imgdoc.openModal() 
            },
            saveimg_document(file,url,typeId){
                this.storeDocument("doc", file, file.name,typeId)
            },
            
            async storeDocument(filename,file, name,typeId){
                 var pId=this.selectedInstance.StudentID
                var postParams = new FormData();
    
                postParams.append(filename,file,name);
                postParams.append('personId',pId);

    
                var response = await this.callAPI(postParams,'savefile')
                //handle response
                if(response.Successful){
                    //if we have a doc to deactivate then ask, else get docs
                    this.responseMessage = "Your uploaded file saved to the student’s record successfully."
                        this.GetFiles();
                    
                      
                }else{
                    this.responseMessage = response.Message
                }
                 
            },

        historyheaderlabelclass(h){
            if(h.isFailure){return "headerlabelfailed"}
            else{return "headerlabel"}
        },
        getComponentName(c){
            var name =c.Name
            if(c.isMajor){name= "* " + name}
            return name
        },
        componentHasCheckOff(c){
            return c.checkOffStyle!=null
        },
        
        updateTime(){
            this.inTimeUpdate=true

        },
        cancelupdateTime(){
            //put the data back
    
            if(this.showTimeAs24){
                this.selectedTimeData={
                    instanceId:this.selectedInstance.ID,
                    enteredCourseDate:this.$options.filters.formatDateForInput(this.selectedInstance.enteredCourseDate),
                    enteredCourseStartTime:this.selectedInstance.enteredCourseStartTime,
                    enteredCourseEndTime:this.selectedInstance.enteredCourseEndTime,
                    enteredBTWMinutes:this.selectedInstance.enteredBTWMinutes,
                    enteredCourseStartTime2:this.selectedInstance.enteredCourseStartTime2,
                    enteredCourseEndTime2:this.selectedInstance.enteredCourseEndTime2,
                    enteredBTWMinutes2:this.selectedInstance.enteredBTWMinutes2,
                    autoTimeItems:[]
                }

                this.starttime24=this.selectedTimeData.enteredCourseStartTime
                this.endtime24=this.selectedTimeData.enteredCourseEndTime
            }else{
                this.selectedTimeData={
                    instanceId:this.selectedInstance.ID,
                    enteredCourseDate:this.$options.filters.formatDateForInput(this.selectedInstance.enteredCourseDate),
                    enteredCourseStartTime:this.$options.filters.formatTimeForInput(this.selectedInstance.enteredCourseStartTime),
                    enteredCourseEndTime:this.$options.filters.formatTimeForInput(this.selectedInstance.enteredCourseEndTime),
                    enteredBTWMinutes:this.selectedInstance.enteredBTWMinutes,
                    enteredCourseStartTime2:this.$options.filters.formatTimeForInput(this.selectedInstance.enteredCourseStartTime2),
                    enteredCourseEndTime2:this.$options.filters.formatTimeForInput(this.selectedInstance.enteredCourseEndTime2),
                    enteredBTWMinutes2:this.selectedInstance.enteredBTWMinutes2,
                    autoTimeItems:[]
                }
            }
            

            this.responseMessage=null
            this.inTimeUpdate=false
        },
        async saveupdateTime(){
            this.responseMessage=null
            if(this.RequireManualEntry){
                if(this.showManualTime && (this.selectedTimeData.enteredCourseStartTime==null || this.selectedTimeData.enteredCourseEndTime==null)){
                    this.responseMessage="Please enter course start and end times before submitting"
                    return
                }
                var sdate = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime)
                var edate = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime)
                if( sdate > edate){
                    this.responseMessage = "Please enter a course end time AFTER your course start time"
                    return
                }

                if(this.selectedGradeSheet.isComboSequence){
                    var sdate2 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime2)
                    var edate2 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime2)
                    if( sdate2 > edate2){
                        this.responseMessage = "Please enter a road course end time AFTER your road course start time"
                        return
                    }

                    if((sdate2 < edate && sdate2 > sdate)||(sdate<edate2 && sdate >sdate2)){
                        this.responseMessage = "Please enter non-overlapping times"
                        return
                    }
                }

            }

    
            if(this.showBTWDropDown){
                this.selectedTimeData.enteredBTWMinutes=this.selectedBTWMinutes
                this.selectedTimeData.enteredBTWMinutes2=this.selectedBTWMinutes2
            }

            var date1= new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime)
            var date2 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime)
            
            var courseMinutes = date1-date2
            courseMinutes= (courseMinutes / (60000));
            console.log('cmin ' + courseMinutes)
            

          
            if(courseMinutes<this.selectedTimeData.enteredBTWMinutes){
                this.responseMessage="Please enter BTW minutes less than or equal to the total course time."
                return
            }
            if(this.selectedGradeSheet.isComboSequence){
                var date3= new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime2)
                var date4 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime2)
                
                var courseMinutes2 = date3-date4
                courseMinutes2= (courseMinutes2 / (60000));
                console.log('cmin2 ' + courseMinutes2)
                if(courseMinutes2<this.selectedTimeData.enteredBTWMinutes2){
                    this.responseMessage="Please enter BTW minutes less than or equal to the total course time."
                    return
                }
            }
            
            

            //if we are warning about time conflicts then do it here. Then we'll do the actual save if its confirmed.
            if(this.warnAboutTimeConflicts ){this.checkForTimeConflicts();return;}
            //if NOT, then go ahead


            let req = {
                ProcedureInstance:this.selectedInstance,
                timeData:this.selectedTimeData
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'savetime')
            //handle response
            if(response.Successful){
                this.inTimeUpdate=false       
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        
        },
        getInstructorName(id){
       
            var name
            this.instructorList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.id==id){
                    name=comp.name
                }
            }
            return "by:" + name
        },
        editPreferences(){
            this.$refs.preferences.Update()
        },
        savePreferences(cols){
            this.multColumnOverride=cols
            this.zoom=this.multiColumnCount
            localStorage.multColumnOverride=cols //store it
          
        },

        formatDateTime(dt){
            return this.$options.filters.formatDateTime_miniwithtime(dt)
        },
        columns(){
            var mult = this.multiColumnCount
            if(this.zoom==mult){
                this.zoom=1
            }else{
                this.zoom=mult
            }
      
        },
         print(divName){
         
            window.print();
            /* var printContents = document.getElementById(divName).innerHTML;
            var originalContents = document.body.innerHTML;

            document.body.innerHTML = printContents;

            window.print();

            document.body.innerHTML = originalContents; */
        },
        addComment(){
            if(!this.online){this.handleOffline(); return}
            this.$refs.addcomment.Update();

        },
        async toggleProficient(){
            if(!this.online){this.handleOffline(); return}
            let req = {
                ProcedureInstance:this.selectedInstance,
                loggedInUser:this.loggedInUser.user_personId

            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'toggleproficiency')
            //handle response
            if(response.Successful){
                this.pleaseWait=false; 
                this.selectedInstance.studentProficiency.StudentIsProficient_ThisItem=!this.selectedInstance.studentProficiency.StudentIsProficient_ThisItem
                this.setproficiencyInstructor()
                
                   
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
            
        },
        openSignModal(docname,docid,doctypename){
            if(!this.online){this.handleOffline(); return}
            this.$refs.signdoc.setValues(docname,docid,doctypename)
            this.$refs.signdoc.openModal()
        },
        openSignModal_Student(docname,docid,doctypename){
            if(!this.online){this.handleOffline(); return}
            this.$refs.signdoc_student.setValues(docname,docid,doctypename)
            this.$refs.signdoc_student.openModal()
        },
        
        grademe(componentId, instance){

        
            this.selectedComponent=componentId
            this.selectedInstance=instance

            if(this.selectedGradeType=='check'){
                var g = this.getGrade(componentId,instance)
                if(this.AllowNAAsGrade){
                  
                    if(g=='P'){
                      this.setGrade('NA')
                    }else if(g=='NA'){
                        this.setGrade(null)
                    }else{
                         this.setGrade('P')
                    }
                }else{
                    if(g=='P'){
                     this.setGrade(null)
                    }else{
                         this.setGrade('P')
                    }
                }
                
            }else{
                this.$refs.grade15.Update();
            }
           

           
   
            
        },
        async saveComment(comment){
            //save back the edited one local
            this.selectedInstance.OverallGradeList_Instructor[0].Comment = comment

            let req = {
                ProcedureInstance:this.selectedInstance,
                comment:comment
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'savecomment')
            //handle response
            if(response.Successful){
                this.refreshComments();         
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async refreshComments(){
            let req = {
                ProcedureInstance:this.selectedInstance,
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'comments')
            //handle response
            if(response.Successful){
                this.selectedInstance.AccumulatedComments = response.comments;        
                this.selectedInstance.HasCommentForThisInstance=response.HasCommentForThisInstance
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async refreshSignature(){
            let req = {
                ProcedureInstance:this.selectedInstance,
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'signature')
            //handle response
            if(response.Successful){
                this.selectedInstance.signatureFileName = response.signatureFileName;        
                this.selectedInstance.signatureFileName_Student = response.signatureFileName_Student; 
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        setGrade(grade, checkOffData=null){
    
            var componentId=this.selectedComponent
            var instId = this.selectedInstance.ID

            this.selectedInstance.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.RelatedID==componentId){
                    comp.Grade=grade
                    comp.checkOffData=checkOffData
                    comp.IsDirty=true //mark dirty
                }
            }
            //also save to instance list that matches!
            var thisInst;
            this.selectedInstanceList.forEach(myFunction3)
            function myFunction3(item, index, arr){
                if(arr[index].ID==instId){
                    thisInst=arr[index]
                }
            }
            thisInst.ProcedureStepComponentGrade_InstructorList.forEach(myFunction4)
            function myFunction4(item, index, arr) {
                let comp=arr[index]
                if(comp.RelatedID==componentId){
                    comp.Grade=grade
                    comp.checkOffData=checkOffData
                    comp.IsDirty=true //mark dirty
                }
            }


            //save to cached instance
            this.saveInstanceToCache()
        },
        isGradable(instance){
         
            return instance.ID==this.selectedInstance.ID && this.selectedInstance.IsLocked==false
        },
        gradebuttonclass(instance){
            if(this.isGradable(instance)){
                return "gradebutton"
            }else{
                return "gradebuttonlocked"
            }
        },
        
        showCheck(componentId, instance){
            var show = false;
            if(this.usingChecks){
                var g = this.getGrade(componentId,instance)
           
                show = (g =='1' || g=='P')
           
            }
            
           return  show
        },
        getGrade(componentId, instance){
         
            var grade;
            instance.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
            
                if(comp.RelatedID==componentId){
                    grade=comp.Grade
                }
            }
           
            //if we are in passfail...change it
            if(this.usingPassFail){
                if(grade=='1'){grade="P"}
                if(grade=='0'){grade="F"}
            }
            if(this.usingChecks){
                if(grade=='0'){grade=""}
            }
         
            return grade
        },
        getCheckOffData(componentId, instance){
            var data;
            instance.ProcedureStepComponentGrade_InstructorList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
            
                if(comp.RelatedID==componentId){
                    data=comp.checkOffData
                }
            }
            return data
        },
        async savegrades(issubmit, waschecked=false){
            this.updateAutoTimeItem()
            this.saveTimeDataToStorage()


            this.responseMessage=null
          
            //if this is a submit and we are offline, no soup
            if(!this.online && issubmit){this.handleOffline(); return}

            //if offline and just save, then OK. just keep cached grades
            if(!this.online){
                this.$emit('gotohome')
                return;
            }


            //if submit and manual, validate the time entries
            if(issubmit && this.isManualTime){
           
                if(this.RequireManualEntry){
                    
                    if (this.showManualTime && (this.selectedTimeData.enteredCourseStartTime==null || this.selectedTimeData.enteredCourseEndTime==null || this.selectedTimeData.enteredCourseStartTime=='' || this.selectedTimeData.enteredCourseEndTime =='' )){
                        this.responseMessage="Please enter course start and end times before submitting"
                        return
                    }
                    var sdate = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime)
                    var edate = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime)
                    if( sdate > edate){
                        this.responseMessage = "Please enter a course end time AFTER your course start time"
                        return
                    }

                    if(this.selectedGradeSheet.isComboSequence){
                        var sdate2 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime2)
                        var edate2 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime2)
                        if( sdate2 > edate2){
                            this.responseMessage = "Please enter a road course end time AFTER your road course start time"
                            return
                        }

                        if((sdate2 < edate && sdate2 > sdate)||(sdate<edate2 && sdate >sdate2) || (edate<edate2 && edate >sdate2) ||(edate2<edate && edate2 >sdate)){
                            this.responseMessage = "Please enter non-overlapping times"
                            return
                        }
                    }

                }

                
                
                //if select btw, use that
                if(this.showBTWDropDown){
                    this.selectedTimeData.enteredBTWMinutes=this.selectedBTWMinutes
                    this.selectedTimeData.enteredBTWMinutes2=this.selectedBTWMinutes2
                }

                //var courseMinutes = Date.parse(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime)-Date.parse(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime)
                var date1= new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime)
                var date2 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime)
                
                var courseMinutes = date1-date2
                courseMinutes= (courseMinutes / (60000));
                
                
                
                if(courseMinutes<this.selectedTimeData.enteredBTWMinutes){
                    this.responseMessage="Please enter BTW minutes less than or equal to the total course time."
                    return
                }

                if(this.selectedGradeSheet.isComboSequence){
                    var date3= new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseEndTime2)
                    var date4 = new Date(this.selectedTimeData.enteredCourseDate + ' ' + this.selectedTimeData.enteredCourseStartTime2)
                    
                    var courseMinutes2 = date3-date4
                    courseMinutes2= (courseMinutes2 / (60000));

                    if(courseMinutes2<this.selectedTimeData.enteredBTWMinutes2){
                        this.responseMessage="Please enter BTW minutes less than or equal to the total course time."
                        return
                    }
                }
                

            }

            //if this sequence has failure rules, check that first
            if(issubmit && this.selectedGradeSheet.hasFailureRules && waschecked==false){
                this.checkGrades()
                return
            }

            //if we are requiring comments... 
            if(this.selectedTimeData.enteredComment!=null){
                this.selectedTimeData.enteredComment = this.selectedTimeData.enteredComment.trim()
            }
            
            this.selectedInstance.HasCommentForThisInstance=(this.selectedTimeData.enteredComment!=null && this.selectedTimeData.enteredComment!='')
            if(window.REQUIRE_XCHARACTER_COMMENT>0 && issubmit){
                if(!this.selectedInstance.HasCommentForThisInstance && !this.lastWasPass){
                    this.responseMessage = "Please enter a comment before submitting"
                    return
                }
            }
            if(this.selectedTimeData.enteredComment.length>4999  ){
                this.responseMessage = "Please enter a comment less than 5000 characters long"
                return
            }

            if(this.RequireSignature && this.selectedInstance.signatureFileName==null && issubmit){
                this.responseMessage = "Please have instructor sign before submitting"
                return
            }
            if(this.RequireSignatureStudent && this.selectedInstance.signatureFileName_Student==null && issubmit && this.selectedGradeSheet.showStudentSignature){
                this.responseMessage = "Please have student sign before submitting"
                return

            }
            if(this.selectedGradeSheet.allowEnterMiles && this.selectedInstance.miles==null && issubmit){
                this.responseMessage = "Please enter miles before submitting"
                return
            }
            //if we are warning about time conflicts then do it here. Then we'll do the actual save if its confirmed.
            if(this.warnAboutTimeConflicts && issubmit){this.checkForTimeConflicts();return;}
            //if NOT, then go ahead

            //send our total/avg/etc along with it
            this.selectedInstance.calculatedTotal = this.calcTotal
            this.selectedInstance.Procedure=this.selectedGradeSheet
            let req = {
                ProcedureInstance:this.selectedInstance,
                submit:issubmit,
                autoStartStopTime:!this.isManualTime,
                timeData:this.selectedTimeData,
                loggedInUser:this.loggedInUser.user_personId,
                loggedInUserName:this.loggedInUser.name
            }
             this.$store.dispatch('beforeAPI');
       
            var response = await this.callAPI(req,'saveprocedureinstance')
            //handle response
            if(response.Successful){
                if (response.Message!=null){
                    this.responseMessage=this.FixSubmitText(response.Message)
                    this.$refs.criticalmessage.show()
                    return
                }
                    this.clearDirtyBitInCache()
                    if(issubmit && this.isManualTime==false){
                        this.removeTimeDataForSubmittedInstance(this.selectedInstance.ID)
                    }
                    //all done go home
                    this.$emit('gotohome')
                
                           
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },

        async checkForTimeConflicts(){
            let req = {
                ProcedureInstance:this.selectedInstance,
                timeData:this.selectedTimeData,
             
            }
             this.$store.dispatch('beforeAPI');
     
            var response = await this.callAPI(req,'checkprocedureinstancetime')
            //handle response
            if(response.Successful){
                //if no time conflicts, just go ahead and finish the submit
                if(response.timeConflictList.length==0){this.finishsubmitinstortime(); return;}

                //show the conficts
                this.timeConflictList= response.timeConflictList

                let mythis = this
                this.timeConflictList.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    comp.evalStartTime=mythis.$options.filters.formatTimeForInput(comp.evalStartTime)
                    comp.evalEndTime=mythis.$options.filters.formatTimeForInput(comp.evalEndTime)
                }

                this.$refs.confirmsubmittime.Show()
               
                           
            }
            this.pleaseWait=false; 
        },
        finishsubmitinstortime(){
            if(this.selectedInstance.IsLocked){
                this.finishsubmittime()
            }else{
                this.finishSubmit()
            }
        },
        async finishSubmit(){
            console.log('finish submit inst')
            //PREPARE the DATA. send our total/avg/etc along with it
            this.selectedInstance.calculatedTotal = this.calcTotal
            this.selectedInstance.Procedure=this.selectedGradeSheet
            let req = {
                ProcedureInstance:this.selectedInstance,
                submit:true,
                autoStartStopTime:!this.isManualTime,
                timeData:this.selectedTimeData,
                loggedInUser:this.loggedInUser.user_personId,
                loggedInUserName:this.loggedInUser.name
            }
             this.$store.dispatch('beforeAPI');
             
            var response = await this.callAPI(req,'saveprocedureinstance')
            //handle response
            if(response.Successful){
                   // this.clearDirtyBitInCache()
                    if( this.isManualTime==false){
                        this.removeTimeDataForSubmittedInstance(this.selectedInstance.ID)
                    }
                    //all done go home
                    this.$emit('gotohome')
                
                           
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async finishsubmittime(){
            console.log('finish submit time')
            let req = {
                ProcedureInstance:this.selectedInstance,
                timeData:this.selectedTimeData
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'savetime')
            //handle response
            if(response.Successful){
                this.inTimeUpdate=false       
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        closecriticalfailure(){
            this.clearDirtyBitInCache()
            if(this.isManualTime==false){
                this.removeTimeDataForSubmittedInstance(this.selectedInstance.ID)
            }
            //all done go home
            this.$emit('gotohome')
        },
        async clockOutStudent(){
            let req = {
                    locationId:this.selectedInstance.LocationID,
                    instructorId:this.selectedInstance.InstructorId,
                    personID:this.selectedInstance.StudentID
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'clockoutstudent')
                //handle response
                if(response.Successful){
                    this.$emit('gotohome')
                    
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false; 
        },
        async checkGrades(){
            //reset
           
            this.lastWasPass=false

            this.selectedInstance.Procedure=this.selectedGradeSheet
            let req = {
                ProcedureInstance:this.selectedInstance,
            }
             this.$store.dispatch('beforeAPI');
     
            var response = await this.callAPI(req,'checkprocedureinstance')
            //handle response
            if(response.Successful){
                if(response.message==null){this.savegrades(true,true)}

                if(response.message.includes('Passed')){
                    
                    this.lastWasPass=true}
                else{
                    
                    this.lastWasPass=false}

                this.confirmSubmitText= this.FixSubmitText(response.message)
        
                this.confirmSubmitTitle = this.FixSubmitText(response.messageTitle)//'Submit ' + this.PassedText
                this.$refs.confirmsubmit.Show()
                //goahead and submit
                //this.savegrades(true,true)
                           
            }else{
                this.confirmSubmitText= response.message
              
                this.confirmSubmitTitle = response.messageTitle//'Submit ' + this.FailedText
                this.$refs.confirmsubmit.Show()
            }
            this.pleaseWait=false; 
        },
        FixSubmitText(text){

            

            var out= text.replace('Passed', this.PassedText)
            out  = out.replace('Failed',this.FailedText)
            out = out.replace('Critical Miss', this.CriticalMissText)
            out = out.replace('CRITICAL MISS', this.CriticalMissText)
            out = out.replace('critical miss(es)', this.CriticalMissText)
            return out
        },
        
          async home(){
            if(this.inTimeUpdate){
                this.responseMessage="Please Save or Cancel your Time Update before closing"
                return
            }
            this.$emit('gotohome')
            
          },
          cancelGrading(){
            
            this.revertCachedChanges()
            this.removeAutoTimeItem()
            this.saveTimeDataToStorage()
            this.$emit('gotohome')
          },
          async savesign_document(file,url,docname,docid, enteredName){
          
            var postParams = new FormData();

            postParams.append("sign",file,name);
            postParams.append('instanceid',this.selectedInstance.ID);
            postParams.append('enteredname',enteredName);

            var response = await this.callAPI(postParams,'savesignature')
            //handle response
            if(response.Successful){
                 this.refreshSignature()
            }else{
                this.responseMessage = response.Message
            }
        },
        async savesign_document_student(file,url,docname,docid, enteredName){
          
          var postParams = new FormData();

          postParams.append("sign",file,name);
          postParams.append('instanceid',this.selectedInstance.ID);
          postParams.append('enteredname',enteredName);

          var response = await this.callAPI(postParams,'savesignaturestudent')
          //handle response
          if(response.Successful){
               this.refreshSignature()
          }else{
              this.responseMessage = response.Message
          }
      },
         //offline
         handleOffline(){
            this.$refs.modalmessage.show()
                
        },
        saveInstanceToCache(){
            console.log('savetocache')
            var storedinstances = localStorage.instances
            if(storedinstances==undefined){return}

            var selInst = this.selectedInstance
            selInst.Procedure = this.selectedGradeSheet
            var myinst
            
            var insts = JSON.parse(storedinstances);
            insts.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    var inst=arr[index]
                    if(inst.ID==selInst.ID){
                        inst=selInst //replace with edited one
                        inst.dirty=true
                        arr[index]=inst
                    }
                }
            localStorage.instances=JSON.stringify(insts)
           
        },
        clearDirtyBitInCache(){
            var storedinstances = localStorage.instances
            if(storedinstances==undefined){return}

            var selInst = this.selectedInstance
            selInst.Procedure = this.selectedGradeSheet
            var myinst
            
            var insts = JSON.parse(storedinstances);
            insts.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    var inst=arr[index]
                    if(inst.ID==selInst.ID){
                        inst.dirty=false
                        arr[index]=inst
                    }
                }
            localStorage.instances=JSON.stringify(insts)
      
        },
        revertCachedChanges(){
            var storedinstances = localStorage.instances
            if(storedinstances==undefined){return}

            var selInst = this.unchangedInstance//get the unchanged instance
            if(this.unchangedInstance==undefined){return}
            var myinst
            var insts = JSON.parse(storedinstances);
            insts.forEach(myFunction2)
                async function myFunction2(item, index, arr) {
                    var inst=arr[index]
                    if(inst.ID==selInst.ID){
                        inst=selInst //replace with edited one
                        
                        inst.dirty=false //we just put it back, doesn't need saving
                        arr[index]=inst
                    }
                }
            localStorage.instances=JSON.stringify(insts)
           
        },

        //TIME DATA
        startGrade(){
            
            if(!this.selectedInstance.IsLocked && this.selectedInstance.employeeTimeCardItemID_Course==0){
               
                this.getInstanceTimeDataFromStorage()
               
                
            }else{
              console.log('use the instance time data')
                if(this.showTimeAs24){
                    this.selectedTimeData={
                        instanceId:this.selectedInstance.ID,
                        enteredCourseDate:this.$options.filters.formatDateForInput(this.selectedInstance.enteredCourseDate),
                        enteredCourseStartTime:this.selectedInstance.enteredCourseStartTime,
                        enteredCourseEndTime:this.selectedInstance.enteredCourseEndTime,
                        enteredBTWMinutes:this.selectedInstance.enteredBTWMinutes,
                        enteredCourseStartTime2:this.selectedInstance.enteredCourseStartTime2,
                        enteredCourseEndTime2:this.selectedInstance.enteredCourseEndTime2,
                        enteredBTWMinutes2:this.selectedInstance.enteredBTWMinutes2,
                        autoTimeItems:[]
                    }
                    this.starttime24=this.selectedTimeData.enteredCourseStartTime
                    this.endtime24=this.selectedTimeData.enteredCourseEndTime
                }else{
                    this.selectedTimeData={
                        instanceId:this.selectedInstance.ID,
                        enteredCourseDate:this.$options.filters.formatDateForInput(this.selectedInstance.enteredCourseDate),
                        enteredCourseStartTime:this.$options.filters.formatTimeForInput(this.selectedInstance.enteredCourseStartTime),
                        enteredCourseEndTime:this.$options.filters.formatTimeForInput(this.selectedInstance.enteredCourseEndTime),
                        enteredBTWMinutes:this.selectedInstance.enteredBTWMinutes,
                        enteredCourseStartTime2:this.$options.filters.formatTimeForInput(this.selectedInstance.enteredCourseStartTime2),
                        enteredCourseEndTime2:this.$options.filters.formatTimeForInput(this.selectedInstance.enteredCourseEndTime2),
                        enteredBTWMinutes2:this.selectedInstance.enteredBTWMinutes2,
                        autoTimeItems:[]
                    }
                }

                
            }
            if(this.showBTWDropDown){
                this.selectedBTWMinutes=this.selectedTimeData.enteredBTWMinutes
                this.selectedBTWMinutes2=this.selectedTimeData.enteredBTWMinutes2
            }

            //we are going into grade mode. place to mark time if needed
            if(this.isManualTime){
                //MANUAL
                
            }else{
                //AUTO
                this.selectedTimeData.autoTimeItems.push(this.getNewAutoTimeItem())
            }
        },
       
        getInstanceTimeDataFromStorage(){
            var instId = this.selectedInstance.ID

            //find in storage by inst id. if not one, add one
            var tmptime = localStorage.timeData

          //  console.log('tmptime ' + tmptime)

            if(tmptime===undefined){
                console.log('in undefined block')
                this.timeData=[this.getNewTimeData()] //get it and add to the list
                this.selectedTimeData =  this.timeData[0] //set it as selected
                return
            }
            try{
                this.timeData = JSON.parse(tmptime);
                var foundTimeData=null
                this.timeData.forEach(myFunction2)
                function myFunction2(item, index, arr) {
                    let comp=arr[index]
                    if(comp.instanceId==instId){
                        foundTimeData=comp
                    }
                }
                //didn't find it, so we need to make/add a new one
                if(foundTimeData==null){
                    
                    var newTimeData = this.getNewTimeData()
                    
                    this.timeData.push(newTimeData)
                    this.selectedTimeData = newTimeData
                
                }else{
                
                    //found it!
                    this.selectedTimeData = foundTimeData
                }
                if(this.showTimeAs24){
                    this.starttime24=this.selectedTimeData.enteredCourseStartTime
                    this.endtime24=this.selectedTimeData.enteredCourseEndTime
                    this.starttime242=this.selectedTimeData.enteredCourseStartTime2
                    this.endtime242=this.selectedTimeData.enteredCourseEndTime2
                }
                this.showComment()
            }catch{
                this.timeData=[this.getNewTimeData()] //get it and add to the list
                this.selectedTimeData =  this.timeData[0] //set it as selected
                return
            }
            
        },
        removeTimeDataForSubmittedInstance(instId){
            //find in storage by inst id. if not one, add one
            var tmptime = localStorage.timeData
            
           
            this.timeData = JSON.parse(tmptime);
            var foundTimeData=null
            var itemindex
            this.timeData.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let comp=arr[index]
                if(comp.instanceId==instId){
                    foundTimeData=comp
                    itemindex = index
                }
            }

            //remove
            this.timeData.splice(itemindex,1)

            //save
            this.saveTimeDataToStorage()
        },
        saveTimeDataToStorage(){
            //make sure we have 24
            if(this.showTimeAs24){
                this.selectedTimeData.enteredCourseStartTime=this.starttime24
                this.selectedTimeData.enteredCourseEndTime=this.endtime24
                this.selectedTimeData.enteredCourseStartTime2=this.starttime242
                this.selectedTimeData.enteredCourseEndTime2=this.endtime242
            }
            if(this.showBTWDropDown){
                this.selectedTimeData.enteredBTWMinutes=this.selectedBTWMinutes
                this.selectedTimeData.enteredBTWMinutes2=this.selectedBTWMinutes2
            }
            localStorage.timeData=JSON.stringify(this.timeData)
        },
        getNewTimeData(){
            
            var time = {
                instanceId:this.selectedInstance.ID,
                enteredCourseDate:this.$options.filters.formatDateForInput(this.selectedInstance.StartDateTime),
                enteredCourseStartTime:'',
                enteredCourseEndTime:'',
                enteredBTWMinutes:0,
                enteredCourseStartTime2:'',
                enteredCourseEndTime2:'',
                enteredBTWMinutes2:0,
                autoTimeItems:[]

            }
            return time
        },
        getNewAutoTimeItem(){
            var item={
                startDateTime:this.$options.filters.formatDateTime_Now(),
                endDateTime:null
            }
            return item
        },
        updateAutoTimeItem(){
            if(!this.isManualTime){
                //set the end
                var index = this.selectedTimeData.autoTimeItems.length-1
                this.selectedTimeData.autoTimeItems[index].endDateTime=this.$options.filters.formatDateTime_Now()
            }
        },
        removeAutoTimeItem(){
            this.selectedTimeData.autoTimeItems.pop();
        }
      },
      mounted(){
        this.multColumnOverride= localStorage.multColumnOverride
        this.zoom=this.multiColumnCount
  
      }
  }
  </script>
  
  <style>
::-webkit-scrollbar{

-webkit-appearance: none;
width: 7px;

}

::-webkit-scrollbar-thumb {

border-radius: 4px;
background-color: rgba(0,0,0,.5); 
-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
  .gradecheckimage{
    height:20px;
  }
  .gradeboxlabel{
    margin:0px;
    padding:0px;
  }
  .gradeDivsingle{
    
    display:grid;
    grid-template-rows: auto auto auto 100px;
    /* 150 */
  }
  .topDiv{
    display:grid;
    grid-template-columns: 280px auto 180px;
    
  }
  .gradesteps{
        padding:5px;
        background-color: var(--frame-color);
    }
  .commentlabelscroll{
    margin:10px;
    padding: 0px 0px 0px 0px;
    height:70px;
    overflow-x:hidden;
    overflow-y:scroll;
  }
  .commentlabelscrolltall{
    margin:10px;
    padding: 0px 0px 0px 0px;
    height:140px;
    overflow-x:hidden;
    overflow-y:scroll;
  }
  .leftborder{
    border-left:1px solid gray;
    margin-bottom:10px;
  }
  .rightborder{
    border-right:1px solid gray;
    margin-bottom:10px;
  }
  .total{
    text-align: left;
    font-size:larger;
    font-weight:bold;
  }
  .studentinfo{

    
  }
  .comments{
    background-color:var(--frame-color);
    margin:0px 10px 10px 10px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .studentinfoandpic{
    display:grid;
    grid-template-columns: 1fr 2fr;
    margin:0px 0px 0px 0px;
  }
.img{
    height:110px;
}
  .gradebutton{
    height:40px;
    width:40px;
    background-color: var(--frame-color);
    border:3px solid green;
    font-size:larger;
    text-align: center;
    text-align-last:center;
    padding:3px;
  }
  .gradebuttonlocked{
    height:40px;
    width:40px;
    background-color: var(--frame-color);
    color:black;
    border:1px solid gray;
    font-size:larger;
    text-align: center;
    padding:3px;
  }
  .gradeinner{
    margin:0px;
    padding:0px;
    text-align: center;
    text-align-last:center;
  }
    .headerlabel{
        /* font-weight:bold; */
        margin:0px;
        /* font-size:larger; */
    }
    .headerlabelfailed{
        margin:0px;
        color:red;
    }
    .steplabel{
        font-size:larger;
        margin:0px 0px 0px 0px ;
        padding: 5px 0px 5px 0px;
        font-weight:bold;
        background-color:#AFBCCE;
        width:100%;
    }
    .componentlabel{
        margin:10px 0px 0px 10px;
    }
    
    .studentname{
        margin:0px 0px 0px 0px;
        /* margin:5px 1px 2px 1px; */
        color:var(--text-color);
        font-size:smaller;
    }
    .studentinfolabel{
        margin:0px 0px 0px 0px;
        /* margin:5px 1px 2px 1px; */
        color:var(--text-color);
        font-size:smaller;
    }
    .redlabel{
        color:red;
        margin:5px 2px 10px 2px;
        font-size:smaller;
    }
    .larger{
        font-size:larger;
        margin-bottom:7px;
    }
    .step{
    
   }
  .component{
    border-bottom: 1px solid lightgray;
    margin-bottom:0px;
    /* margin-bottom:5px; */
    
  }
  .componentdiv{
    display:grid;
  }
  .buttons{
    display:flex;
    width:100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .filter{

  }
  .graderow{
    text-align:left;
    margin:0px;
    border-radius: 2px;
    padding:0px;
    /* padding:5px; */
    display:grid;
    grid-template-columns: 250px repeat(12,60px);
  }
  .graderowmulti{
    text-align:left;
    margin:0px;
    border-radius: 2px;
    padding:0px; 
    /* padding:5px; */
    display:grid;
    grid-template-columns: 250px 60px;
  }


  .headerrow{
    text-align:center;
    margin:0px;
    border-radius: 2px;
    padding:0px;
    display:grid;
    grid-template-columns: 250px repeat(12,60px);
  }
  .widebutton{

    border: none;
    border-radius:5px;
    width:180px;
    height:50px;
    padding:0px;
    font-size: medium;
    margin:10px;
    text-decoration: none;

  }
  .narrowbutton{

    border: none;
    border-radius:5px;
    width:50px;
    height:30px;
    padding:0px;
    font-size: medium;
    margin:5px;
    text-decoration: none;

}
.recordbutton{

border: none;
border-radius:5px;
width:70px;
height:30px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;

}
.innerbutton{

border: none;
border-radius:5px;
width:80px;
height:25px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;

}
.innerbuttonwide{
    border: none;
border-radius:5px;
width:120px;
height:25px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;
}
.innerbuttonprof3{
    border: none;
border-radius:5px;
width:120px;
height:45px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;
}
.filterfield{
    height:30px;
    margin:5px;
}
.filterfieldshort{
    height:30px;
    margin:5px;
    width:60px;
}
.filterselect{
    height:35px;
    margin:5px; 
    min-width: 125px;
}
.finishbutton{

border: none;
border-radius:5px;
width:150px;
height:40px;
padding:0px;
font-size: medium;
margin:10px;
text-decoration: none;

}
.btwdropdown{
    height:35px;
    margin:5px;
    font:inherit;
}


.zoom1{
    grid-template-columns:1fr;
}
.zoom2{
    grid-template-columns:1fr 1fr;
}
.zoom3{
    grid-template-columns:1fr 1fr 1fr;
}
.zoom4{
    grid-template-columns:1fr 1fr 1fr 1fr;
}
.zoom5{
    grid-template-columns:1fr 1fr 1fr 1fr 1fr;
}
.zoom6{
    grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr;
}
.sig{
    height:25px;
}
.manualTimeLabel{
    font-size:medium;
    margin:7px 0px 0px 0px;
    color:var(--text-color);
    min-width:120px;
}
.manualTimeLabel2{
    font-size:medium;
    margin:12px 0px 0px 0px;
    color:var(--text-color);
}
.time24{
    margin:5px 10px 10px 10px;
}
.commentinput2{
    width:97%;
    margin-left:3px;
}
  </style>

/* if(componentId==5){
    this.selectedGradeType='check'
    var g = this.getGrade(componentId,instance)
    if(g=='P'){
        this.setGrade(null)
    }else{
        this.setGrade('P')
    }
    
    return;
}

switch (componentId) {
    case 1:
        this.selectedGradeType='1to5'
        break;
    case 2:
        this.selectedGradeType='1to4'
        break;
    case 3:
        this.selectedGradeType='pf'
        break;
    case 4:
        this.selectedGradeType='enter'
        break;
    
        

    default:
        break;
} */

/* let req = {
    ProcedureInstance:this.selectedInstance,
    autoStartStopTime:!this.isManualTime
}
 this.$store.dispatch('beforeAPI');
var response = await this.callAPI(req,'canceltime')
//handle response
if(response.Successful){
    this.$emit('gotohome')           
}else{
    this.responseMessage = response.Message
}
this.pleaseWait=false;  */